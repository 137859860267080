import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useGlobalContext } from "context/globalContext";
import { ENDPOINT } from "constants/environment";
import { IoMdClose } from "react-icons/io";

const API_URL = `${ENDPOINT}/app/v1/systemprompt`;

const FormPopup = ({
  isOpen,
  onClose,
  onSubmit,
  isEditMode,
  initialFormData,
}) => {
  const { getSystemPromptData } = useGlobalContext();
  const { formData, setFormData, initialFormState } = useGlobalContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isEditMode && initialFormData) {
      setFormData({
        ...initialFormData,
        value: initialFormData.value || "",
      });
    } else {
      setFormData(initialFormState);
    }
  }, [isEditMode, initialFormData, initialFormState, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
    }
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, [isOpen]);

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   setFormData({
  //     ...formData,
  //     value: data,
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.key || !formData.version) {
      toast.error("Key and Version are required.");
      return;
    }

    setIsSubmitting(true);

    try {
      const payload = {
        ...formData,
        status: formData.status === "active",
        value: formData.value,
      };

      const response = formData.id
        ? await axios.put(API_URL, payload)
        : await axios.post(API_URL, payload);

      const successMessage = formData.id
        ? "Prompt updated successfully"
        : "Prompt created successfully";
      toast.success(successMessage);

      onSubmit(response.data);
      onClose();
      setFormData(initialFormState);
      getSystemPromptData();
    } catch (error) {
      toast.error("Error creating/updating prompt. Please try again.");
      console.error("Error creating/updating prompt:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setFormData(initialFormState);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div className="relative h-screen max-h-[90vh] w-4/5 overflow-auto rounded-xl bg-white shadow-xl">
        <button
          type="button"
          onClick={handleCancel}
          className="absolute top-6 right-6 text-gray-700 hover:text-gray-900 focus:outline-none"
        >
          <IoMdClose size={24} />
        </button>
        <form onSubmit={handleSubmit} className="h-full w-full p-6">
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex-1">
                <label
                  htmlFor="key"
                  className="mb-2 block font-bold text-gray-700"
                >
                  Key:
                </label>
                <input
                  id="key"
                  type="text"
                  name="key"
                  value={formData.key || ""}
                  onChange={handleChange}
                  className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isSubmitting}
                />
              </div>
              <div className="flex-1">
                <label
                  htmlFor="version"
                  className="mb-2 block font-bold text-gray-700"
                >
                  Version:
                </label>
                <input
                  id="version"
                  type="text"
                  name="version"
                  value={formData.version || ""}
                  onChange={handleChange}
                  className="focus:border-transparent w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 sm:flex-row">
              <div className="flex-1">
                <label className="mb-2 block font-bold text-gray-700">
                  Status:
                </label>
                <div className="flex items-center">
                  <label className="mr-4">
                    <input
                      type="radio"
                      name="status"
                      value="active"
                      checked={formData.status === "active"}
                      onChange={handleChange}
                      className="relative top-0.5 mr-1 cursor-pointer"
                      disabled={isSubmitting}
                    />
                    Active
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="inactive"
                      checked={formData.status === "inactive"}
                      onChange={handleChange}
                      className="relative top-0.5 mr-1 cursor-pointer"
                      disabled={isSubmitting}
                    />
                    In-Active
                  </label>
                </div>
              </div>
              <div className="flex-1">
                <label
                  htmlFor="comment"
                  className="mb-2 block font-bold text-gray-700"
                >
                  Comment:
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  value={formData.comment || ""}
                  onChange={handleChange}
                  className="focus:border-transparent h-11 w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isSubmitting}
                ></textarea>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="w-full max-w-full">
                <label className="mb-2 block font-bold text-gray-700">
                  Value:
                </label>
                <textarea
                  id="value"
                  name="value"
                  value={formData.value || ""}
                  onChange={handleChange}
                  className="focus:border-transparent h-[50vh] w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isSubmitting}
                ></textarea>
                {/* <CKEditor
                  editor={ClassicEditor}
                  data={formData.value || ""}
                  config={{
                    toolbar: {
                      items: [
                        "undo",
                        "redo",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "subscript",
                        "superscript",
                        "|",
                        "alignment:left",
                        "alignment:center",
                        "alignment:right",
                        "alignment:justify",
                        "|",
                        "link",
                        "insertTable",
                        "mediaEmbed",
                        "blockQuote",
                        "codeBlock",
                        "|",
                        "bulletedList",
                        "numberedList",
                      ],
                    },
                    language: "en",
                    textDirection: "ltr", // Ensure text direction is set to left-to-right
                  }}
                  onChange={handleEditorChange}
                  disabled={isSubmitting}
                /> */}
              </div>
            </div>

            <div className="my-4 mb-6 flex justify-end space-x-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-green-600 py-2 px-4 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                {isSubmitting ? "Submitting..." : isEditMode ? "Update" : "Add"}
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="rounded-md bg-red-600 py-2 px-4 text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormPopup;
